@font-face {
  font-family: "acumin";
  src: url("../../assets/fonts/AcuminVariableConcept.ttf");
}

body {
  font-family: "acumin";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-variation-settings: "wght" 700;
}
